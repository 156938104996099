.nft-user-menu-away {
  display: none;
}
.nft-user-menu-away.active {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}
.newest-item-slider-area .slick-slide,
.related-products-slider .slick-slide {
  margin: 0;
}
.newest-item-slider-area .slick-slide.slick-active.slick-current > div {
  margin: 0 12px;
}
.offcanvas-social ul li a {
  display: block;
}
.related-products-slider .slick-slide > div {
  margin: 0 15px;
}
